import axios from "@/plugins/axios";

const urlPath = "/platform/api/v1/companies/";

const getCompanies = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

const getCompany = (crNumber) => {
  return axios.get(`${urlPath}${crNumber}`);
};

const getCompanySales = (payload) => {
  return axios.get(
    `${urlPath}${payload.cr_number}/statistic/sales-chart?${payload.params}`
  );
};

const getCompanyBasketValue = (payload) => {
  return axios.get(
    `${urlPath}${payload.cr_number}/statistic/backet-value-chart?${payload.params}`
  );
};

const getCompanyBasketSize = (payload) => {
  return axios.get(
    `${urlPath}${payload.cr_number}/statistic/backet-size-chart?${payload.params}`
  );
};

const getCompanyCustomers = (payload) => {
  return axios.get(
    `${urlPath}${payload.cr_number}/statistic/customers-chart?${payload.params}`
  );
};

const getCompanyCustomersGrowth = (payload) => {
  return axios.get(
    `${urlPath}${payload.cr_number}/statistic/customers-growth-chart?${payload.params}`
  );
};

const getCustomerRetentionRate = (payload) => {
  return axios.get(
    `${urlPath}${payload.cr_number}/statistic/customers-retention-chart?${payload.params}`
  );
};

const getCounters = (payload) => {
  return axios.get(
    `${urlPath}${payload.cr_number}/statistic/counters?${payload.params}`
  );
};

export default {
  getCompanies,
  getCompany,
  getCompanySales,
  getCounters,
  getCompanyBasketValue,
  getCompanyBasketSize,
  getCompanyCustomers,
  getCompanyCustomersGrowth,
  getCustomerRetentionRate,
};
