import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/dashboard",
    name: "Home",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/Dashboard.vue"),
  },
  {
    path: "/sign-in",
    name: "Sign in",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/email-not-confirmed",
    name: "Email not confirmation",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/NotConfirmed.vue"),
  },
  {
    path: "/account-suspended",
    name: "Account suspended",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/Suspended.vue"),
  },
  {
    path: "/sign-in-tries-limit",
    name: "Sign-in attempt limit",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/LoginLimit.vue"),
  },
  {
    path: "/email-confirmation",
    name: "Email confirmation",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/Confirmation.vue"),
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/ForgotPassword.vue"),
  },
  {
    path: "/reset-password",
    name: "Password update",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/CreatePassword.vue"),
  },
  {
    path: "/customers",
    name: "Customers",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/CustomersList.vue"),
  },
  {
    path: "/customers/:id",
    name: "Customer",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/Customer.vue"),
  },
  {
    path: "/requests",
    name: "Requests",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/FinancingRequests.vue"),
  },
  {
    path: "/requests/:id",
    name: "Request",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/RequestDetail.vue"),
  },
  {
    path: "/loans",
    name: "Loans",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/Loans.vue"),
  },
  {
    path: "/loans/:id",
    name: "Loan",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/LoanDetail.vue"),
  },
  {
    path: "/orders",
    name: "Orders",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/OrdersList.vue"),
  },
  {
    path: "/orders/:id",
    name: "Order",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/OrderDetail.vue"),
  },
  {
    path: "/sign-in/otp",
    name: "OTP code",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/OtpView.vue"),
  },
  {
    path: "/sign-in/otp-recovery",
    name: "OTP recovery code",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/OtpRecovery.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/Profile.vue"),
  },
  {
    path: "/staff",
    name: "Staff",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/StaffList.vue"),
  },
  {
    path: "/staff/:id",
    name: "Edit staff account",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/StaffMember.vue"),
  },
  {
    path: "/partners",
    name: "Partners",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/PartnersList.vue"),
  },
  {
    path: "/partners/:id",
    name: "Partner",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/PartnerDetail.vue"),
  },
  {
    path: "/companies/:id",
    name: "Company",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/Company.vue"),
  },
  {
    path: "*",
    redirect: { name: "Home" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
