import axios from "@/plugins/axios";

const urlPath = "/platform/api/v1/partners/";

const getPartners = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

const createPartner = (data) => {
  return axios.post(`${urlPath}`, data);
};

const getPartner = (id) => {
  return axios.get(`${urlPath}${id}`);
};

const partnerAction = (partnerId, data) => {
  return axios.post(`${urlPath}${partnerId}/action`, data);
};

const getPartnerStaff = (id) => {
  return axios.get(`${urlPath}${id}/staff/`);
};

const createPartnerStaff = (payload) => {
  return axios.post(`${urlPath}${payload.partnerId}/staff/`, payload.data);
};

const resendPartnerStaffEmail = (payload) => {
  return axios.post(
    `${urlPath}${payload.partnerId}/staff/${payload.staffId}/send-email-confirmation`
  );
};

const resetPartnerStaffPassword = (payload) => {
  return axios.post(
    `${urlPath}${payload.partnerId}/staff/${payload.staffId}/reset-password`
  );
};

const partnerStaffAction = (payload) => {
  return axios.post(
    `${urlPath}${payload.partnerId}/staff/${payload.staffId}/action`,
    payload.data
  );
};

const updatePartnerStaff = (payload) => {
  return axios.put(
    `${urlPath}${payload.partnerId}/staff/${payload.staffId}`,
    payload.data
  );
};

export default {
  getPartners,
  createPartner,
  getPartner,
  partnerAction,
  getPartnerStaff,
  createPartnerStaff,
  resendPartnerStaffEmail,
  resetPartnerStaffPassword,
  partnerStaffAction,
  updatePartnerStaff,
};
