import requestApi from "@/api/request";
import { i18n } from "@/plugins/i18n";
// import { i18n } from "@/plugins/i18n";

const state = {
  isLoading: false,
  requests: {},
  errors: [],
  request: {},
};

const mutations = {
  resetRequestsErrors(state) {
    state.errors = [];
  },
  getRequestsStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getRequestsSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.requests = payload;
  },
  getRequestsFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  getRequestStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getRequestSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.request = payload;
  },
  getRequestFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  approveRequestStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  approveRequestSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.request = payload;
  },
  approveRequestFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
};

const actions = {
  async getRequests(context, payload) {
    try {
      context.commit("getRequestsStart");

      const res = await requestApi.getRequests(payload);
      context.commit("getRequestsSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getRequestsFailed", e.response.data.error);
      return false;
    }
  },

  async getRequest(context, payload) {
    try {
      context.commit("getRequestStart");

      const res = await requestApi.getRequest(payload);
      context.commit("getRequestSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getRequestFailed", e.response.data.error);
      return false;
    }
  },

  async approveRequest(context, payload) {
    try {
      context.commit("approveRequestStart");

      const res = await requestApi.approveRequest(
        payload.requestNumber,
        payload.requestBody
      );
      context.commit("approveRequestSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("approveRequestFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {
  requestLoanAmountErrors({ errors }) {
    const result = [];

    errors.find((item) => item == "offered_amount__required") &&
      result.push(i18n.t("errors.requestLoanAmountError"));
    errors.find((item) => item == "offered_amount__invalid") &&
      result.push(i18n.t("errors.amountRange"));
    return result;
  },
  requestTermErrors({ errors }) {
    const result = [];

    errors.find((item) => item == "offered_term__required") &&
      result.push(i18n.t("errors.requestTermError"));
    errors.find((item) => item == "offered_term__invalid") &&
      result.push(i18n.t("errors.termRange"));
    return result;
  },
  requestPercentErrors({ errors }) {
    const result = [];

    errors.find((item) => item == "offered_interest_rate__required") &&
      result.push(i18n.t("errors.requestPercentError"));
    errors.find((item) => item == "offered_interest_rate__invalid") &&
      result.push(i18n.t("errors.interestRateRange"));
    return result;
  },
  offeredRateErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "offered_rate__required") &&
      result.push(i18n.t("errors.offeredRateError"));
    errors.find((item) => item === "offered_rate__invalid") &&
      result.push(i18n.t("errors.offeredRateRange"));
    return result;
  },
  requestsList(state) {
    return state.requests?.result || [];
  },

  requestDetail(state) {
    return state.request || [];
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
