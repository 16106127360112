<template>
  <div class="d-flex flex-column flex-grow-1">
    <EmptyHeader />
    <div class="flex-grow-1 d-flex align-center justify-center">
      <notifications></notifications>

      <router-view />
    </div>
  </div>
</template>
<script>
import EmptyHeader from "@/components/shared/EmptyHeader";
export default {
  components: { EmptyHeader },

  watch: {
    $route() {
      this.$store.commit("resetAuthErrors");
    },
  },
};
</script>
<style lang="scss" scoped></style>
