import axios from "@/plugins/axios";

const urlPath = "/platform/api/v1/loans/";

const getLoans = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

const getLoan = (id) => {
  return axios.get(`${urlPath}${id}`);
};

const getLoanTransactions = (id) => {
  return axios.get(`${urlPath}${id}/transactions/`);
};

const activateLoan = (loanNumber, data) => {
  return axios.post(`${urlPath}${loanNumber}/action`, data);
};

export default {
  getLoans,
  getLoan,
  getLoanTransactions,
  activateLoan,
};
