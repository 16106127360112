import Vue from "vue";
import Vuex from "vuex";
import notification from "@/store/modules/notification";

import auth from "@/store/modules/auth.js";
import profile from "@/store/modules/profile.js";
import staff from "@/store/modules/staff.js";
import customer from "@/store/modules/customer.js";
import company from "@/store/modules/company.js";
import request from "@/store/modules/request.js";
import loans from "@/store/modules/loans.js";
import partners from "@/store/modules/partners";
import offerings from "@/store/modules/offerings";
import orders from "@/store/modules/orders";
import statistics from "@/store/modules/statistics";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    profile,
    staff,
    customer,
    company,
    request,
    loans,
    partners,
    offerings,
    orders,
    notification,
    statistics,
  },
});
