import partnersApi from "@/api/partners";
import { i18n } from "@/plugins/i18n";

const state = {
  isLoading: false,
  isSubmitting: false,
  isResending: false,
  isResetting: false,
  partner: {},
  partners: [],
  staff: [],
  errors: [],
};

const mutations = {
  resetPartnersErrors(state) {
    state.errors = [];
  },
  getPartnersStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getPartnersSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.partners = payload;
  },
  getPartnersFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  createPartnerStart(state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  createPartnerSuccess(state) {
    state.isSubmitting = false;
  },
  createPartnerFailed(state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  getPartnerStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getPartnerSuccess(state, payload) {
    state.isLoading = false;
    state.partner = payload;
  },
  getPartnerFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  actionPartnerStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  actionPartnerSuccess(state, payload) {
    state.partner = payload;
    state.isLoading = false;
    state.errors = [];
  },
  actionPartnerFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  getPartnerStaffStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getPartnerStaffSuccess(state, payload) {
    state.isLoading = false;
    state.staff = payload;
  },
  getPartnerStaffFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  createPartnerStaffStart(state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  createPartnerStaffSuccess(state) {
    state.isSubmitting = false;
  },
  createPartnerStaffFailed(state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  resendEmailStart(state) {
    state.isResending = true;
    state.errors = [];
  },
  resendEmailSuccess(state) {
    state.isResending = false;
    state.errors = [];
  },
  resendEmailFailed(state, payload) {
    state.isResending = false;
    state.errors = payload;
  },

  resetPasswordStaffStart(state) {
    state.isResetting = true;
    state.errors = [];
  },
  resetPasswordStaffSuccess(state) {
    state.isResetting = false;
    state.errors = [];
  },
  resetPasswordStaffFailed(state, payload) {
    state.isResetting = false;
    state.errors = payload;
  },
};

const actions = {
  async getPartners(context, payload) {
    try {
      context.commit("getPartnersStart");

      const res = await partnersApi.getPartners(payload);
      context.commit("getPartnersSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getPartnersFailed", e.response.data.error);
      return false;
    }
  },

  async createPartner(context, payload) {
    try {
      context.commit("createPartnerStart");

      const res = await partnersApi.createPartner(payload);
      context.commit("createPartnerSuccess", res.data);
      return res;
    } catch (e) {
      context.commit("createPartnerFailed", e.response.data.error);
      return e.response;
    }
  },

  async getPartner(context, payload) {
    try {
      context.commit("getPartnerStart");

      const res = await partnersApi.getPartner(payload);
      context.commit("getPartnerSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getPartnerFailed", e.response.data.error);
      return false;
    }
  },

  async actionWithPartner(context, payload) {
    try {
      context.commit("actionPartnerStart");

      const res = await partnersApi.partnerAction(
        payload.partnerId,
        payload.data
      );
      context.commit("actionPartnerSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("actionPartnerFailed", e.response.data.error);
      return false;
    }
  },

  async getPartnerStaff(context, payload) {
    try {
      context.commit("getPartnerStaffStart");

      const res = await partnersApi.getPartnerStaff(payload);
      context.commit("getPartnerStaffSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getPartnerStaffFailed", e.response.data.error);
      return false;
    }
  },

  async createPartnerStaff(context, payload) {
    try {
      context.commit("createPartnerStaffStart");

      await partnersApi.createPartnerStaff(payload);
      context.commit("createPartnerStaffSuccess");
      return true;
    } catch (e) {
      context.commit("createPartnerStaffFailed", e.response.data.error);
      return false;
    }
  },

  async resendPartnerStaffEmail(context, payload) {
    try {
      context.commit("resendEmailStart");

      const res = await partnersApi.resendPartnerStaffEmail(payload);
      context.commit("resendEmailSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("resendEmailFailed", e.response.data.error);
      return false;
    }
  },

  async resetPartnerStaffPassword(context, payload) {
    try {
      context.commit("resetPasswordStaffStart");

      await partnersApi.resetPartnerStaffPassword(payload);
      context.commit("resetPasswordStaffSuccess");
      return true;
    } catch (e) {
      context.commit("resetPasswordStaffFailed", e.response.data.error);
      return false;
    }
  },

  async actionWithPartnerStaff(context, payload) {
    try {
      context.commit("actionPartnerStart");

      const res = await partnersApi.partnerStaffAction(payload);
      context.commit("actionPartnerSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("actionPartnerFailed", e.response.data.error);
      return false;
    }
  },

  async updatePartnerStaffRole(context, payload) {
    try {
      context.commit("actionPartnerStart");

      const res = await partnersApi.updatePartnerStaff(payload);
      context.commit("actionPartnerSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("actionPartnerFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {
  partnerNameErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "name__required") &&
      result.push(i18n.t("errors.nameRequired"));
    errors.find((i) => i === "name__invalid") &&
      result.push(i18n.t("errors.nameInvalid"));
    return result;
  },
  partnerEmailErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "email__invalid") &&
      result.push(i18n.t("errors.emailInvalid"));
    errors.find((i) => i === "email__required") &&
      result.push(i18n.t("errors.emailRequired"));
    return result;
  },

  partnerStaffFirstNameErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "first_name__required") &&
      result.push(i18n.t("errors.firstNameRequired"));
    errors.find((i) => i === "first_name__invalid") &&
      result.push(i18n.t("errors.firstNameInvalid"));
    return result;
  },
  partnerStaffLastNameErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "last_name__required") &&
      result.push(i18n.t("errors.lastNameRequired"));
    errors.find((i) => i === "last_name__invalid") &&
      result.push(i18n.t("errors.lastNameInvalid"));
    return result;
  },
  partnerStaffEmailErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "email__required") &&
      result.push(i18n.t("errors.emailRequired"));
    errors.find((i) => i === "email__invalid") &&
      result.push(i18n.t("errors.emailInvalid"));
    return result;
  },
  partnerStaffRoleErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "role__required") &&
      result.push(i18n.t("errors.roleRequired"));
    errors.find((i) => i === "role__invalid") &&
      result.push(i18n.t("errors.roleInvalid"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
