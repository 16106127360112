<template>
  <div class="d-flex align-center justify-space-between px-8 py-5 header">
    <v-img
      alt="Logo"
      :src="require('@/assets/icons/logo.svg')"
      max-width="218"
      height="34"
    />

    <v-spacer />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },

  computed: {
    isSignin() {
      return this.$route.name === "Sign in";
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  border-bottom: 1px solid #e3e3e3;
}
</style>
