import axios from "@/plugins/axios";

const urlPath = "/platform/api/v1/offering/";

const getOfferings = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

export default {
  getOfferings,
};
