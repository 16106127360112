import Vue from "vue";
import Vuetify from "vuetify";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/dist/vuetify.min.css";
import RequestsPurple from "@/assets/icons/customIcons/RequestsPurple.vue";
import OrdersPurple from "@/assets/icons/customIcons/OrdersPurple.vue";
import Download from "@/assets/icons/customIcons/Download.vue";
import InvoiceIcon from "@/assets/icons/customIcons/InvoiceIcon.vue";
import SelectorDropdown from "@/assets/icons/customIcons/SelectorDropdown.vue";

Vue.use(Vuetify);

export const vuetify = new Vuetify({
  rtl: false,
  icons: {
    iconfont: "mdi",
    values: {
      "requests-purple": {
        component: RequestsPurple,
      },
      "orders-purple": {
        component: OrdersPurple,
      },
      "rapid-download": {
        component: Download,
      },
      "rapid-invoice": {
        component: InvoiceIcon,
      },
      "rapid-selector-dropdown": {
        component: SelectorDropdown,
      },
    },
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: {
          base: "#6C3CEA",
        },
        "light-blue": "#FDFDFD",
        "blue-1": "#D8E5EB",
        "gray-1": "#737B8B",
        "gray-2": "#B0C3CC",
        "gray-3": "#909AB0",
        "gray-4": "#7F7F7F",
        "gray-6": "#F7F7F7",
        "gray-7": "#F5F8FA",

        "gray-5": "#E5E5E5",
        error: "#C90000",

        "purple-1": "#6C3CEA",
        "purple-2": "#302E75",
        "purple-3": "#707FDD",
        "purple-4": "#8282C6",
        "purple-5": "#8D71FA",
        green: "#03A439",
        "light-green": "#1BAB18",
        yellow: "#F1AC09",
        blue: "#0992F5",
        pink: "#FA71DC",
      },
    },
  },
});
