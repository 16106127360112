import statisticsApi from "@/api/statistics";
// import { i18n } from "@/plugins/i18n";

const state = {
  isLoading: false,
  counters: {},
  errors: [],
};

const mutations = {
  getMetricsCountersStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getMetricsCountersSuccess(state, payload) {
    state.isLoading = false;
    state.counters = payload;
    state.errors = [];
  },
  getMetricsCountersFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
};

const actions = {
  async getMetricsCounters(context) {
    try {
      context.commit("getMetricsCountersStart");

      const res = await statisticsApi.getCounters();
      context.commit("getMetricsCountersSuccess", res.data);

      return true;
    } catch (e) {
      context.commit("getMetricsCountersFailed", e.response.data.error);

      return false;
    }
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
