import offeringsApi from "@/api/offerings";

const state = {
  isLoading: false,
  offerings: [],
  errors: [],
};

const mutations = {
  resetOfferingsErrors(state) {
    state.errors = [];
  },
  getOfferingsStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getOfferingsSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.offerings = payload;
  },
  getOfferingsFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
};

const actions = {
  async getOfferings(context, payload) {
    try {
      context.commit("getOfferingsStart");

      const res = await offeringsApi.getOfferings(payload);
      context.commit("getOfferingsSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getOfferingsFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
