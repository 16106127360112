export const getItem = (key) => {
  try {
    return JSON.parse(sessionStorage.getItem(key));
  } catch (error) {
    console.log("Error getting data from sessionstorage");
    return null;
  }
};

export const setItem = (key, data) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    console.log("Error saving data in sessionstorage");
  }
};
