import loansApi from "@/api/loans";

const state = {
  isLoading: false,
  loans: {},
  errors: [],
  loan: null,
  loanTransactions: [],
};

const mutations = {
  resetLoansErrors(state) {
    state.errors = [];
  },
  getLoansStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getLoansSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.loans = payload;
  },
  getLoansFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  getLoanStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getLoanSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.loan = payload;
  },
  getLoanFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  getLoanTransactionsStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getLoanTransactionsSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.loanTransactions = payload;
  },
  getLoanTransactionsFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  activateLoanStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  activateLoanSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.loan = payload;
  },
  activateLoanFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
};

const actions = {
  async getLoans(context, payload) {
    try {
      context.commit("getLoansStart");

      const res = await loansApi.getLoans(payload);
      context.commit("getLoansSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getLoansFailed", e.response.data.error);
      return false;
    }
  },

  async getLoan(context, payload) {
    try {
      context.commit("getLoanStart");

      const res = await loansApi.getLoan(payload);
      context.commit("getLoanSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getLoanFailed", e.response.data.error);
      return false;
    }
  },

  async getLoanTransactions(context, payload) {
    try {
      context.commit("getLoanTransactionsStart");

      const res = await loansApi.getLoanTransactions(payload);
      context.commit("getLoanTransactionsSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getLoanTransactionsFailed", e.response.data.error);
      return false;
    }
  },

  async activateLoan(context, payload) {
    try {
      context.commit("activateLoanStart");

      const res = await loansApi.activateLoan(
        payload.loanNumber,
        payload.requestBody
      );
      context.commit("activateLoanSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("activateLoanFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {
  loansList(state) {
    return state.loans?.result || [];
  },

  loanDetail(state) {
    return state.loan || [];
  },

  loanInstallments(state) {
    return state.loan?.installments || [];
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
