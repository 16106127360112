import customersApi from "@/api/customer";
// import { i18n } from "@/plugins/i18n";

const state = {
  isLoading: false,
  isSubmitting: false,
  customers: {},
  errors: [],
  customer: {},
};

const mutations = {
  resetCustomersErrors(state) {
    state.errors = [];
  },
  getCustomersStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getCustomersSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.customers = payload;
  },
  getCustomersFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
  getCustomerStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getCustomerSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.customer = payload;
  },
  getCustomerFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
};

const actions = {
  async getCustomers(context, payload) {
    try {
      context.commit("getCustomersStart");

      const res = await customersApi.getCustomers(payload);
      context.commit("getCustomersSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getCustomersFailed", e.response.data.error);
      return false;
    }
  },
  async getCustomer(context, payload) {
    try {
      context.commit("getCustomerStart");

      const res = await customersApi.getCustomer(payload);
      context.commit("getCustomerSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getCustomerFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {
  customersList(state) {
    return state.customers?.result || [];
  },
  customerDetail(state) {
    return state.customer || [];
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
