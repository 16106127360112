<template>
  <div class="main">
    <notifications></notifications>
    <Menu />
    <Header />
    <router-view />
  </div>
</template>
<script>
import Menu from "@/components/shared/Menu.vue";
import Header from "@/components/shared/Header.vue";

export default {
  components: { Header, Menu },
};
</script>
<style lang="scss" scoped>
.main {
  padding-left: 240px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
