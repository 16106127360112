import companyApi from "@/api/company";
import { i18n } from "@/plugins/i18n";
// import { i18n } from "@/plugins/i18n";

const state = {
  isLoading: false,
  companies: {},
  errors: [],
  chartErrors: [],
  company: {},
  companySales: {},
  basketValue: {},
  basketSize: {},
  customersChart: {},
  customersGrowthChart: {},
  retentionRateChart: {},
  isCountersLoading: false,
  counters: null,
};

const mutations = {
  resetCompanies(state) {
    state.companies = {};
  },
  resetCompaniesErrors(state) {
    state.errors = [];
  },
  getCompaniesStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getCompaniesSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.companies = payload;
  },
  getCompaniesFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  getCompanyStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getCompanySuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.company = payload;
  },
  getCompanyFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  getCompanySalesStart(state) {
    state.isLoading = true;
    state.chartErrors = [];
  },
  getCompanySalesSuccess(state, payload) {
    state.isLoading = false;
    state.chartErrors = [];
    state.companySales = payload;
  },
  getCompanySalesFailed(state, payload) {
    state.isLoading = false;
    state.chartErrors = payload;
  },

  getCompanyBasketValueStart(state) {
    state.isLoading = true;
    state.chartErrors = [];
  },
  getCompanyBasketValueSuccess(state, payload) {
    state.isLoading = false;
    state.chartErrors = [];
    state.basketValue = payload;
  },
  getCompanyBasketValueFailed(state, payload) {
    state.isLoading = false;
    state.chartErrors = payload;
  },

  getCompanyBasketSizeStart(state) {
    state.isLoading = true;
    state.chartErrors = [];
  },
  getCompanyBasketSizeSuccess(state, payload) {
    state.isLoading = false;
    state.chartErrors = [];
    state.basketSize = payload;
  },
  getCompanyBasketSizeFailed(state, payload) {
    state.isLoading = false;
    state.chartErrors = payload;
  },

  getCompanyCustomersStart(state) {
    state.isLoading = true;
    state.chartErrors = [];
  },
  getCompanyCustomersSuccess(state, payload) {
    state.isLoading = false;
    state.chartErrors = [];
    state.customersChart = payload;
  },
  getCompanyCustomersFailed(state, payload) {
    state.isLoading = false;
    state.chartErrors = payload;
  },

  getCompanyCustomersGrowthStart(state) {
    state.isLoading = true;
    state.chartErrors = [];
  },
  getCompanyCustomersGrowthSuccess(state, payload) {
    state.isLoading = false;
    state.chartErrors = [];
    state.customersGrowthChart = payload;
  },
  getCompanyCustomersGrowthFailed(state, payload) {
    state.isLoading = false;
    state.chartErrors = payload;
  },

  getCustomersRetentionRateStart(state) {
    state.isLoading = true;
    state.chartErrors = [];
  },
  getCustomersRetentionRateSuccess(state, payload) {
    state.isLoading = false;
    state.chartErrors = [];
    state.retentionRateChart = payload;
  },
  getCustomersRetentionRateFailed(state, payload) {
    state.isLoading = false;
    state.chartErrors = payload;
  },

  getCountersStart(state) {
    state.isCountersLoading = true;
    state.errors = [];
  },
  getCountersSuccess(state, payload) {
    state.isCountersLoading = false;
    state.errors = [];
    state.counters = payload;
  },
  getCountersFailed(state, payload) {
    state.isCountersLoading = false;
    state.errors = payload;
  },
};

const actions = {
  async getCompanies(context, payload) {
    try {
      context.commit("getCompaniesStart");

      const res = await companyApi.getCompanies(payload);
      context.commit("getCompaniesSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getCompaniesFailed", e.response.data.error);
      return false;
    }
  },

  async getCompany(context, payload) {
    try {
      context.commit("getCompanyStart");

      const res = await companyApi.getCompany(payload);
      context.commit("getCompanySuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getCompanyFailed", e.response.data.error);
      return false;
    }
  },

  async getCompanySales(context, payload) {
    try {
      context.commit("getCompanySalesStart");

      const res = await companyApi.getCompanySales(payload);
      context.commit("getCompanySalesSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getCompanySalesFailed", e.response.data.error);
      return false;
    }
  },

  async getCompanyBasketValue(context, payload) {
    try {
      context.commit("getCompanyBasketValueStart");

      const res = await companyApi.getCompanyBasketValue(payload);
      context.commit("getCompanyBasketValueSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getCompanyBasketValueFailed", e.response.data.error);
      return false;
    }
  },

  async getCompanyBasketSize(context, payload) {
    try {
      context.commit("getCompanyBasketSizeStart");

      const res = await companyApi.getCompanyBasketSize(payload);
      context.commit("getCompanyBasketSizeSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getCompanyBasketSizeFailed", e.response.data.error);
      return false;
    }
  },

  async getCompanyCustomers(context, payload) {
    try {
      context.commit("getCompanyCustomersStart");

      const res = await companyApi.getCompanyCustomers(payload);
      context.commit("getCompanyCustomersSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getCompanyCustomersFailed", e.response.data.error);
      return false;
    }
  },

  async getCompanyCustomersGrowth(context, payload) {
    try {
      context.commit("getCompanyCustomersGrowthStart");

      const res = await companyApi.getCompanyCustomersGrowth(payload);
      context.commit("getCompanyCustomersGrowthSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getCompanyCustomersGrowthFailed", e.response.data.error);
      return false;
    }
  },

  async getCustomersRetentionRate(context, payload) {
    try {
      context.commit("getCustomersRetentionRateStart");

      const res = await companyApi.getCustomerRetentionRate(payload);
      context.commit("getCustomersRetentionRateSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getCustomersRetentionRateFailed", e.response.data.error);
      return false;
    }
  },

  async getCounters(context, payload) {
    try {
      context.commit("getCountersStart");

      const res = await companyApi.getCounters(payload);
      context.commit("getCountersSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getCountersFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {
  companiesList(state) {
    return state.companies?.result || [];
  },

  companyDetail(state) {
    return state.company || [];
  },

  companySales(state) {
    return state.companySales || [];
  },

  chartNotAvailableErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "data__not_available") &&
      result.push(i18n.t("inputError.firstNameRequired"));

    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
