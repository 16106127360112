import router from "@/router";

export const pushToNotificationUrl = (item) => {
  const currentPath = window.location.pathname;
  let targetPath = "";

  if (item.data?.offering_order) {
    targetPath = `/orders/${item.data?.offering_order?.number}`;
  } else if (item.data?.loan) {
    targetPath = `/financing/${item.data?.loan?.number}`;
  } else if (item.data?.request) {
    targetPath = `/requests/${item.data?.request?.number}`;
  }

  if (currentPath !== targetPath) {
    router.push(targetPath);
  }
};
