import axios from "@/plugins/axios";

const urlPath = "/platform/api/v1/requests/";

const getRequests = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

const approveRequest = (requestNumber, data) => {
  return axios.post(`${urlPath}${requestNumber}/action`, data);
};

const getRequest = (id) => {
  return axios.get(`${urlPath}${id}`);
};

export default {
  getRequests,
  getRequest,
  approveRequest,
};
