import axios from "@/plugins/axios";

const urlPath = "/platform/api/v1/metrics/counters";

const getCounters = () => {
  return axios.get(`${urlPath}`);
};

export default {
  getCounters,
};
