import axios from "@/plugins/axios";

const urlPath = "/platform/api/v1/customers/";

const getCustomers = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

const getCustomer = (id) => {
  return axios.get(`${urlPath}${id}`);
};

export default {
  getCustomers,
  getCustomer,
};
