import axios from "@/plugins/axios";

const urlPath = "/platform/api/v1/offering-orders/";

const getOrders = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

const getOrder = (id) => {
  return axios.get(`${urlPath}${id}`);
};

const orderAction = (orderId, data) => {
  return axios.post(`${urlPath}${orderId}/action`, data);
};

const orderInvoice = (orderId) => {
  return axios.get(`${urlPath}${orderId}/invoice`, { responseType: "blob" });
};

const outerInvoice = (orderId) => {
  return axios.get(`${urlPath}${orderId}/outer-partner-invoice`, {
    responseType: "blob",
  });
};

export default {
  getOrders,
  getOrder,
  orderAction,
  orderInvoice,
  outerInvoice,
};
