import ordersApi from "@/api/orders";
import { i18n } from "@/plugins/i18n";

const state = {
  isLoading: false,
  isStatusLoading: false,
  orders: [],
  order: {},
  errors: [],
};

const mutations = {
  resetOrdersErrors(state) {
    state.errors = [];
  },
  getOrdersStart(state) {
    state.isLoading = true;
    state.errors = [];
    state.order = {};
  },
  getOrdersSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.orders = payload;
  },
  getOrdersFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  getOrderStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getOrderSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.order = payload;
  },
  getOrderFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  actionOfferingOrderStart(state) {
    state.isStatusLoading = true;
    state.errors = [];
  },
  actionOfferingOrderSuccess(state, payload) {
    state.isStatusLoading = false;
    state.order = payload;
  },
  actionOfferingOrderFailed(state, payload) {
    state.isStatusLoading = false;
    state.errors = payload;
  },
};

const actions = {
  async getOrders(context, payload) {
    try {
      context.commit("getOrdersStart");

      const res = await ordersApi.getOrders(payload);
      context.commit("getOrdersSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getOrdersFailed", e.response.data.error);
      return false;
    }
  },

  async getOrder(context, payload) {
    try {
      context.commit("getOrderStart");

      const res = await ordersApi.getOrder(payload);
      context.commit("getOrderSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getOrderFailed", e.response.data.error);
      return false;
    }
  },

  async actionOrder(context, payload) {
    try {
      context.commit("actionOfferingOrderStart");

      const res = await ordersApi.orderAction(
        payload.orderId,
        payload.requestBody
      );
      context.commit("actionOfferingOrderSuccess", res.data);
      return res;
    } catch (e) {
      context.commit("actionOfferingOrderFailed", e.response.data.error);
      return e.response;
    }
  },

  async getOrderInvoice(context, payload) {
    try {
      const res = await ordersApi.orderInvoice(payload);

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${payload}-invoice.pdf`);
      document.body.appendChild(link);
      link.click();

      return true;
    } catch (e) {
      return false;
    }
  },

  async getOuterInvoice(context, payload) {
    try {
      const res = await ordersApi.outerInvoice(payload);

      console.log(res);

      const type = res.headers["content-type"].split("/").slice(-1)[0] || "pdf";

      console.log(type);

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `invoice.${type}`);
      document.body.appendChild(link);
      link.click();

      return true;
    } catch (e) {
      return false;
    }
  },
};

const getters = {
  offeredTermErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "offered_term__required") &&
      result.push(i18n.t("errors.offeredTermRequired"));
    errors.find((i) => i === "offered_terms__required") &&
      result.push(i18n.t("errors.offeredTermRequired"));
    errors.find((i) => i === "offered_term__invalid") &&
      result.push(i18n.t("errors.offeredTermInvalid"));
    return result;
  },

  offeredTermRejectionErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "rejection_reason__required") &&
      result.push(i18n.t("errors.rejectionReasonRequired"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
