import axios from "@/plugins/axios";

const urlPath = "/platform/api/v1/notifications/";

const getNotifications = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

const setSeenAll = () => {
  return axios.post(`${urlPath}seen-all`);
};

const getNewMsgCount = () => {
  return axios.get(`${urlPath}count`);
};

const setSeenMessage = (id) => {
  return axios.post(`${urlPath}${id}/seen`);
};

export default {
  getNotifications,
  setSeenAll,
  setSeenMessage,
  getNewMsgCount,
};
