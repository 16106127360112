<template>
  <div class="purple-2 menu pt-4">
    <div class="d-flex justify-center mb-12">
      <v-img
        max-width="173"
        height="64"
        :src="require('@/assets/icons/logo-white.svg')"
      />
    </div>

    <v-list flat color="transparent" class="pt-4">
      <v-list-item-group v-for="item in items" :key="item.title">
        <v-list-item
          v-if="!item.subLinks"
          :to="`/${item.link}`"
          active-class="menu-item--active"
          link
          class="font-weight-medium ms-4 me-6 my-1"
        >
          <v-list-item-icon class="my-4 me-3">
            <v-img
              height="20"
              max-width="20"
              :src="getImage(item.icon)"
              class="menu-icon"
            ></v-img>
          </v-list-item-icon>
          <v-list-item-content class="py-2">
            <v-list-item-title class="menu__item-title">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group v-else :key="item.title" no-action append-icon="">
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-icon class="my-4 me-3">
                <v-img
                  height="20"
                  max-width="20"
                  :src="getImage(item.icon)"
                  class="menu-icon"
                ></v-img>
              </v-list-item-icon>
              <v-list-item-content class="py-2">
                <v-list-item-title class="menu__item-title">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            v-for="sublink in item.subLinks"
            :to="`/${sublink.link}`"
            :key="sublink.text"
            link
            active-class="menu-item--active"
          >
            <v-list-item-icon class="my-4 me-3">
              <v-img
                v-if="isActiveLink(sublink.link)"
                height="20"
                max-width="20"
                :src="require(`@/assets/icons/white-dot.svg`)"
                class="menu-icon"
              ></v-img>
            </v-list-item-icon>
            <v-list-item-content class="py-2">
              <v-list-item-title class="menu__item-title">{{
                sublink.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list-item-group>
    </v-list>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          title: this.$t("headers.dashboard"),
          link: "dashboard",
          icon: "dashboard",
        },
        {
          title: this.$tc("headers.customer", 2),
          link: "customers",
          icon: "customers",
        },
        {
          title: this.$tc("headers.loan", 2),
          link: "loans",
          icon: "loans",
        },
        {
          title: this.$tc("headers.request", 2),
          link: "requests",
          icon: "requests",
        },
        {
          title: this.$tc("orders.title", 2),
          link: "orders",
          icon: "orders",
        },
        {
          title: this.$tc("headers.statement", 2),
          link: "",
          icon: "statements",
        },
        {
          title: this.$tc("headers.partner", 2),
          link: "partners",
          icon: "partners",
        },
        {
          title: this.$t("headers.staff"),
          link: "staff",
          icon: "staff",
        },
      ],
    };
  },

  methods: {
    getImage(icon) {
      return require(`@/assets/icons/menu/${icon}.svg`);
    },

    isActiveLink(link) {
      return this.$route.path.startsWith(`/${link}`);
    },
  },

  computed: {},
};
</script>

<style lang="scss" scoped>
.menu {
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 240px;

  & .v-list {
    padding: 0 !important;
  }
  & .v-list-item {
    padding-left: 24px !important;
    border-radius: 5px;
    transition: 0.2s background;
  }

  &__item-title {
    color: #8282c6;
    z-index: 1;
  }

  & .v-list-group__items {
    a {
      height: 52px;
      margin: 4px 16px;
    }
  }
}

.menu-item--active {
  background: rgba(185, 194, 255, 0.15);

  & .menu__item-title {
    color: white;
  }
  & .menu-icon {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
      hue-rotate(299deg) brightness(102%) contrast(102%);
  }
}

.items-group {
  &__title {
    color: rgba(255, 255, 255, 0.8);
  }

  &__line {
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
  }
}
</style>
